.MuiTab-root {
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    font-size: 0.8rem!important;
}

.MuiTab-wrapper {
    flex-direction: row!important;
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    display: flex;
    flex-direction: row;
    gap: 6px;
}

/* .nameButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: bold;
} */

.MuiTab-root {
    min-width: 0px!important;
}

.notDraggable {
    user-select: none!important;
    -moz-user-select: none!important;
    -webkit-user-drag: none!important;
    -webkit-user-select: none!important;
    -ms-user-select: none!important;
}
