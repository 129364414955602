/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.gridList::-webkit-scrollbar {
    background-color:#fff;
    width: 10px;
    height: 10px;
}

/* background of the scrollbar except button or resizer */
.gridList::-webkit-scrollbar-track {
    background-color:#fff
}
.gridList::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
.gridList::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:2px solid #fff
}
.gridList::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:2px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.gridList::-webkit-scrollbar-button {display:none}

.gridList {

    overflow-y: scroll
}

.allLargeur {
    width: 100%
}