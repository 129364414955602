
@font-face {
    font-family: 'Ailerons';
    src: local('Ailerons-Regular'),
    url('./Ailerons-Regular.ttf') format('woff');
  }

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url('./Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url('./Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url('./Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('./Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Light'), local('Rubik-Light'),
        url('./Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
        url('./Rubik-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Medium'), local('Rubik-Medium'),
        url('./Rubik-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
        url('./Rubik-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Italic'), local('Rubik-Italic'),
        url('./Rubik-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Bold'), local('Rubik-Bold'),
        url('./Rubik-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Black'), local('Rubik-Black'),
        url('./Rubik-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
        url('./Rubik-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
        url('./Rubik-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Bold'),
            local('Product Sans Bold'),
            url('./Product Sans Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Bold Italic'),
            local('Product Sans Bold Italic'),
            url('./Product Sans Bold Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Italic'),
            local('Product Sans Italic'),
            url('./Product Sans Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Regular'),
            local('Product Sans Regular'),
            url('./Product Sans Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}