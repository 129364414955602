.App {
  text-align: center;
  width: 100%;
  /* height: 100%; */
}


.loadingScreenContainer {
  background-color: black;
  height: 100%;
  color: white;
  display: grid;
  font-size: xx-large;
  place-items: center;
}

.loadingSvg{
  width: 40%;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #d2d9fe;
}

::-webkit-scrollbar-thumb {
    background: #171a29;
}