@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap);
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
	list-style: none;
	text-align: start;
}

body {
	margin: 0;
	font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html,
body,
#root {
	overflow: visible;
	overflow: initial;
	width: 100%;
}

.MuiTypography-root, .MuiButton-root, .MuiMenuItem-root, button{
	font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fc-timegrid-event-harness {
	cursor: pointer;
}

.fc-event-main:hover {
  cursor: pointer;
}

.App {
  text-align: center;
  width: 100%;
  /* height: 100%; */
}


.loadingScreenContainer {
  background-color: black;
  height: 100%;
  color: white;
  display: grid;
  font-size: xx-large;
  place-items: center;
}

.loadingSvg{
  width: 40%;

}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #d2d9fe;
}

::-webkit-scrollbar-thumb {
    background: #171a29;
}

@font-face {
    font-family: 'Ailerons';
    src: local('Ailerons-Regular'),
    url(/static/media/Ailerons-Regular.c7d5266a.ttf) format('woff');
  }

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url(/static/media/Rubik-Regular.b3d0902b.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Regular'), local('Rubik-Regular'),
        url(/static/media/Rubik-Regular.b3d0902b.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Regular'), local('Roboto-Regular'),
        url(/static/media/Roboto-Regular.11eabca2.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url(/static/media/Roboto-Bold.e07df86c.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Light'), local('Rubik-Light'),
        url(/static/media/Rubik-Light.7a06846b.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Black Italic'), local('Rubik-BlackItalic'),
        url(/static/media/Rubik-BlackItalic.849377dd.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Medium'), local('Rubik-Medium'),
        url(/static/media/Rubik-Medium.c87313aa.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Medium Italic'), local('Rubik-MediumItalic'),
        url(/static/media/Rubik-MediumItalic.56583c23.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Italic'), local('Rubik-Italic'),
        url(/static/media/Rubik-Italic.9a5eb2e5.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Bold'), local('Rubik-Bold'),
        url(/static/media/Rubik-Bold.9a6fb6f5.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Black'), local('Rubik-Black'),
        url(/static/media/Rubik-Black.fba2019b.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Bold Italic'), local('Rubik-BoldItalic'),
        url(/static/media/Rubik-BoldItalic.c002cd78.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: local('Rubik Light Italic'), local('Rubik-LightItalic'),
        url(/static/media/Rubik-LightItalic.4fd638a6.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Bold'),
            local('Product Sans Bold'),
            url("/static/media/Product Sans Bold.dba0c688.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Bold Italic'),
            local('Product Sans Bold Italic'),
            url("/static/media/Product Sans Bold Italic.79750b1d.ttf") format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Italic'),
            local('Product Sans Italic'),
            url("/static/media/Product Sans Italic.e88ec188.ttf") format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Product Sans';
    src: local('Product Sans Regular'),
            local('Product Sans Regular'),
            url("/static/media/Product Sans Regular.eae9c18c.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.MuiTab-root {
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    font-size: 0.8rem!important;
}

.MuiTab-wrapper {
    flex-direction: row!important;
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    display: flex;
    flex-direction: row;
    grid-gap: 6px;
    gap: 6px;
}

/* .nameButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: bold;
} */

.MuiTab-root {
    min-width: 0px!important;
}

.notDraggable {
    user-select: none!important;
    -moz-user-select: none!important;
    -webkit-user-drag: none!important;
    -webkit-user-select: none!important;
    -ms-user-select: none!important;
}

/* fix pour react swipeable first tab */
.react-swipeable-view-container {
    transition: -webkit-transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
    transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
    transition: transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s, -webkit-transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important;
}


.page-enter{
   opacity: 0;
}
.page-exit{
   opacity: 1;
}
.page-enter-active{
   opacity: 1;
}
.page-exit-active{
   opacity: 0;
}
.page-enter-active,
.page-exit-active{
   transition: opacity 200ms;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.gridList::-webkit-scrollbar {
    background-color:#fff;
    width: 10px;
    height: 10px;
}

/* background of the scrollbar except button or resizer */
.gridList::-webkit-scrollbar-track {
    background-color:#fff
}
.gridList::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
.gridList::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:2px solid #fff
}
.gridList::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:2px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.gridList::-webkit-scrollbar-button {display:none}

.gridList {

    overflow-y: scroll
}

.allLargeur {
    width: 100%
}
.zonePreviewContainer{
    margin-top: 40px;
}

.postPreviewUpvoteContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postPreviewBottomPart{
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-bottom: 10px;
}

.postPreviewAvatar{
    border-radius: 50%;
    border: 8px solid;
    top: -20px;
    width: 58px !important;
    height: 58px !important;
    object-fit: cover;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    /* margin-bottom: 10px; */
    border-color: white;
    position: relative;
}

.postPreviewTexte{
    font-size: 16px;
}

.postPreviewTitre{
    font-size: 20px;
    font-weight: bold;
}

.postPreviewAuteur{
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.postPreviewTopMiddle{
    display: flex;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.postPreviewMiddlePart{
    z-index: 9;
    top: -19px;
    padding:19px;
    position: relative;
    text-align: left;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.postPreviewTopPart{
    z-index: 10;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: inherit;
    border-radius: 15px;
    height: 60px;
}

.postPreviewContainer{
    /* margin: 60px; */
    margin-top: 60px;
    margin-bottom: 60px;
    width: 450px;
    border-radius: 15px;
    border: 0.75px solid #707070;
}

.postPreviewShadowContainer{
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

.postPreviewShadow{
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

/* .feedContainer{

} */


.zoneListItemText{
    font-size: 20px;
}

.zoneItemContainer{
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.zoneItemContainer:hover{
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.fluxContainer{
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-width: 350px;
    overflow: auto;
    align-items: center;
}

.selectButton{
    width: 60%;
    text-align: left;
    width: 275px;
    height: 46px;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    padding-left: 22px;
    font-size: 20px;
    font-weight: bold;
}

.selectButton:hover{
    -webkit-transform: translate(-5px,-5px);
            transform: translate(-5px,-5px);
}
.selectButton{
    transition: box-shadow 0.1s ease-in-out, -webkit-transform 0.1s 0s ease-in-out;
    transition: transform 0.1s 0s ease-in-out, box-shadow 0.1s ease-in-out;
    transition: transform 0.1s 0s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-transform 0.1s 0s ease-in-out;

}

.selectButton:focus{
    outline: none;
}

.buttonContainer{
    text-align: center;
}

.sideBarSectionTitre{
    font-size: 30px;
    font-weight: bold;
}

.sideBarContainer{
    margin: 50px;
    width: 360px;
    text-align: left;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.oneThirdContainer{
    width: 33vw
}
.zonePreviewContainer{
    margin-top: 40px;
}

.postPreviewUpvoteContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postPreviewBottomPart{
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-bottom: 10px;
}

.postPreviewAvatar{
    border-radius: 50%;
    border: 8px solid;
    top: -20px;
    width: 58px !important;
    height: 58px !important;
    object-fit: cover;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    /* margin-bottom: 10px; */
    border-color: white;
    position: relative;
}

.postPreviewTexte{
    font-size: 16px;
}

.postPreviewTitre{
    font-size: 20px;
    font-weight: bold;
}

.postPreviewAuteur{
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.postPreviewTopMiddle{
    display: flex;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.postPreviewMiddlePart{
    z-index: 9;
    top: -19px;
    padding:19px;
    position: relative;
    text-align: left;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.postPreviewTopPart{
    z-index: 10;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: inherit;
    border-radius: 15px;
    height: 60px;
}

.postPreviewContainer{
    /* margin: 60px; */
    margin-top: 60px;
    margin-bottom: 60px;
    width: 450px;
    border-radius: 15px;
    border: 0.75px solid #707070;
}

.postPreviewShadowContainer{
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

.postPreviewShadow{
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

/* .feedContainer{

} */


.zoneListItemText{
    font-size: 20px;
}

.zoneItemContainer{
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.zoneItemContainer:hover{
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.fluxContainer{
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-width: 350px;
    overflow: auto;
    align-items: center;
}

.selectButton{
    width: 60%;
    text-align: left;
    width: 275px;
    height: 46px;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    padding-left: 22px;
    font-size: 20px;
    font-weight: bold;
}

.selectButton:hover{
    -webkit-transform: translate(-5px,-5px);
            transform: translate(-5px,-5px);
}
.selectButton{
    transition: box-shadow 0.1s ease-in-out, -webkit-transform 0.1s 0s ease-in-out;
    transition: transform 0.1s 0s ease-in-out, box-shadow 0.1s ease-in-out;
    transition: transform 0.1s 0s ease-in-out, box-shadow 0.1s ease-in-out, -webkit-transform 0.1s 0s ease-in-out;

}

.selectButton:focus{
    outline: none;
}

.buttonContainer{
    text-align: center;
}

.sideBarSectionTitre{
    font-size: 30px;
    font-weight: bold;
}

.sideBarContainer{
    margin: 50px;
    width: 360px;
    text-align: left;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.oneThirdContainer{
    width: 33vw
}
