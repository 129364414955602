.page-enter{
   opacity: 0;
}
.page-exit{
   opacity: 1;
}
.page-enter-active{
   opacity: 1;
}
.page-exit-active{
   opacity: 0;
}
.page-enter-active,
.page-exit-active{
   transition: opacity 200ms;
}
