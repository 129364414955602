.zonePreviewContainer{
    margin-top: 40px;
}

.postPreviewUpvoteContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postPreviewBottomPart{
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-bottom: 10px;
}

.postPreviewAvatar{
    border-radius: 50%;
    border: 8px solid;
    top: -20px;
    width: 58px !important;
    height: 58px !important;
    object-fit: cover;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    /* margin-bottom: 10px; */
    border-color: white;
    position: relative;
}

.postPreviewTexte{
    font-size: 16px;
}

.postPreviewTitre{
    font-size: 20px;
    font-weight: bold;
}

.postPreviewAuteur{
    margin-left: 15px;
    font-size: 20px;
    font-weight: bold;
}

.postPreviewTopMiddle{
    display: flex;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.postPreviewMiddlePart{
    z-index: 9;
    top: -19px;
    padding:19px;
    position: relative;
    text-align: left;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.postPreviewTopPart{
    z-index: 10;
    display: flex;
    padding-left: 20px;
    align-items: center;
    background-color: inherit;
    border-radius: 15px;
    height: 60px;
}

.postPreviewContainer{
    /* margin: 60px; */
    margin-top: 60px;
    margin-bottom: 60px;
    width: 450px;
    border-radius: 15px;
    border: 0.75px solid #707070;
}

.postPreviewShadowContainer{
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

.postPreviewShadow{
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.16);
}

/* .feedContainer{

} */


.zoneListItemText{
    font-size: 20px;
}

.zoneItemContainer{
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.zoneItemContainer:hover{
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.fluxContainer{
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    min-width: 350px;
    overflow: auto;
    align-items: center;
}

.selectButton{
    width: 60%;
    text-align: left;
    width: 275px;
    height: 46px;
    border-radius: 10px;
    text-decoration: none;
    border: none;
    padding-left: 22px;
    font-size: 20px;
    font-weight: bold;
}

.selectButton:hover{
    transform: translate(-5px,-5px);
}
.selectButton{
    transition: transform 0.1s 0s ease-in-out, box-shadow 0.1s ease-in-out;

}

.selectButton:focus{
    outline: none;
}

.buttonContainer{
    text-align: center;
}

.sideBarSectionTitre{
    font-size: 30px;
    font-weight: bold;
}

.sideBarContainer{
    margin: 50px;
    width: 360px;
    text-align: left;
}

.flex-center{
    display: flex;
    justify-content: center;
}

.oneThirdContainer{
    width: 33vw
}